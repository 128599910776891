import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';

import { store } from 'redux/store.js';
import ResetStyle from "./styled/ResetStyle";
import GlobalStyle from "./styled/GlobalStyle";

ReactDOM.render(
	<React.Fragment>
		<ResetStyle />
		<GlobalStyle />
		<Provider store={store}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</Provider>
	</React.Fragment>,
	document.getElementById('root')
);