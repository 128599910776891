import React from 'react';
import styled from 'styled-components';

import fetchablePastLaunches from 'redux/fetchablePastLaunches';
import FetchableDataProviderFor from './FetchableDataProviderFor';
import YoutubePlayerOpenButton from './YoutubePlayerOpenButton';
import Card from './Card';

const FetchablePastLaunches = FetchableDataProviderFor(
	fetchablePastLaunches,
	state => ({ ...state.pastLaunches })
);

const S = {};
S.Card = styled(Card)`
	flex: 0.8;
`;
S.List = styled.ul`
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
`;
S.ListElem = styled.li`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
S.ListElemLeft = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
`;
S.ListElemText = styled.div`
	flex: 1;
	line-height: 1.5em;
`;
S.ListElemImg = styled.img`
	max-width: 3em;
	min-width: 3em;
	min-height: 3em;
	max-height: 3em;
	margin-right: 1em;
		
	opacity: 0;
	&[src*='http'] {
		opacity: 1;
	}
`;
S.ListElemButton = styled(YoutubePlayerOpenButton)`
	margin-left: 1em;
`;
S.TimeSince = styled.p`
	color: gray;
`;

const PastLaunches = ({ className }) => {
	return (
		<S.Card className={className}>
			<Card.Title>Recent Activity</Card.Title>
			<Card.Content>
				<S.List>
					<FetchablePastLaunches>
						{(missions) => missions.map(mission => (
							<S.ListElem key={mission.id}>
								<S.ListElemLeft>
									<S.ListElemImg src={mission.img} />
									<S.ListElemText>
										<S.TimeSince>{ mission.timeSince }</S.TimeSince>
										<h1>{ mission.title }</h1>
									</S.ListElemText>
								</S.ListElemLeft>
								<S.ListElemButton data-testid='PastLaunchYTButton'
									youtubeID={mission.youtubeID}
								/>
							</S.ListElem>
						))}
					</FetchablePastLaunches>
				</S.List>
			</Card.Content>
		</S.Card>
	);
};

export default PastLaunches;