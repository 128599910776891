import styled, { css } from 'styled-components';

const DEFAULT_COLOR = 'gray';
const V_PADDING = 0.4;
const HEIGHT = 1.5;
const colorPropOrDefault = props => props.color || DEFAULT_COLOR;

const StylePrimary = css`
	background-color: ${colorPropOrDefault};
	color: white;
`;

const StyleSecondary = css`
	background-color: transparent;
	color: ${colorPropOrDefault};
	border: 2px solid ${colorPropOrDefault};
`;

const StyleButton = css`
	${props => props.primary ? StylePrimary : StyleSecondary};
	
	overflow: hidden;
	border-radius: 0.2em;
	padding: ${V_PADDING}em 1.5em;
	height: ${HEIGHT + V_PADDING * 2}em;
	line-height: ${HEIGHT}em;
	font-weight: bold;
	cursor: pointer;
	
	:hover {
		filter: brightness(120%);
	}
	
	:active {
		filter: brightness(80%);
	}
`;

export const Button = styled.button`
	${StyleButton}
`;

export const ButtonLink = styled.a`
	${StyleButton}
`;