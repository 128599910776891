import { DateTime, Interval } from 'luxon';

import FetchableData from "./FetchableData";

const timeAgo = date => {
	const time = DateTime.fromJSDate(new Date(date));
	const now = DateTime.local().toUTC();
	const timeInterval = Interval.fromDateTimes(time, now);

	return intervalToTimeAgo(timeInterval);
};

const intervalToTimeAgo = interval => {
	const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
	const agoObj = interval.toDuration(units).toObject();
	const largestUnit = units.find(unit => agoObj[unit] > 0);
	const largestUnitAmount = agoObj[largestUnit];

	return `${largestUnitAmount} ${largestUnit} ago`;
};

export default new FetchableData({
	name: 'PAST_LAUNCHES',
	url: 'https://api.spacexdata.com/v3/launches/past?order=desc&sort=launch_date_utc&limit=6',
	dataMiddleware:
		data =>
			data.map(
				({ mission_name, launch_date_utc, links, flight_number }) => ({
					id: flight_number,
					title: mission_name,
					timeSince: timeAgo(launch_date_utc),
					img: links?.mission_patch_small,
					youtubeID: links?.youtube_id,
				})
			),
});