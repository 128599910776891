import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	html {
		box-sizing: border-box;
	}
	
	*, *:before, *:after {
		box-sizing: inherit;
	}
	
	body {
		font-size: 1em;
		font-family: sans-serif;
		width: 100%;
		max-width: 100%;
		min-height: 100%;
	}
	
	h1 {
		font-size: 1.2em;
		font-weight: bold;
	}
`;