import React from 'react';
import styled from 'styled-components';

import NextLaunch from './components/NextLaunch';
import PastLaunches from './components/PastLaunches';
import YoutubePlayer from './components/YoutubePlayer';
import SiteHeader from './components/SiteHeader';
import './fontAwesome';

const S = {};
S.Container = styled.div`
	width: 100%;
	max-width: 80em;
	margin-left: auto;
	margin-right: auto;
	@media screen and (min-width: 90em) {
		font-size: 1.2em;
	}
`;
S.LaunchInfo = styled.div`
	display: flex;
	flex-direction: column-reverse;
		
	@media screen and (min-width: 70em) {
		flex-direction: row;
		align-items: flex-end;
		flex-wrap: wrap-reverse;
	}
`;

const App = () => (
	<S.Container>
		<SiteHeader />
		<YoutubePlayer />
		<S.LaunchInfo>
			<PastLaunches />
			<NextLaunch />
		</S.LaunchInfo>
	</S.Container>
);

export default App;