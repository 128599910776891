import { combineReducers} from "redux";

import fetchableNextLaunch from "../fetchableNextLaunch";
import fetchablePastLaunches from "../fetchablePastLaunches";
import youtubePlayer from './youtubePlayer';

export default combineReducers({
	nextLaunch: fetchableNextLaunch.reducer,
	pastLaunches: fetchablePastLaunches.reducer,
	youtubePlayer,
});