import {
	createStore,
	compose,
	applyMiddleware
} from 'redux';
import ReduxThunk from 'redux-thunk';

import rootReducer from './reducers/index';

const devTools = window?.__REDUX_DEVTOOLS_EXTENSION__;

export const middlewares = [ ReduxThunk ];

export const store = createStore(
	rootReducer,
	compose(
		applyMiddleware(...middlewares),
		devTools ? devTools() : f => f,
	),
);