import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import YoutubeVideo from './YoutubeVideo';
import { Button } from 'styled/Button';
import { setYoutubeID } from 'redux/actions/youtubePlayer';

const Styled = {};

Styled.Container = styled.div`
	margin: 1em;
`;

Styled.ButtonContainer = styled.div`
	margin: 1em;
	display: flex;
	justify-content: center;
`;

export const YoutubePlayer = ({ closePlayer, youtubeID }) => {
	if (!youtubeID)
		return null;

	return (
		<Styled.Container data-testid='YoutubePlayer'>
			<YoutubeVideo youtubeID={youtubeID} />
			<Styled.ButtonContainer>
				<Button
					onClick={closePlayer}
				>
					Close Video Player
				</Button>
			</Styled.ButtonContainer>
		</Styled.Container>
	);
};

const mapStateToProps = state => ({
	youtubeID: state.youtubePlayer.youtubeID,
});
const mapDispatchToProps = dispatch => ({
	closePlayer: () => dispatch(setYoutubeID(null)),
});
export default connect(mapStateToProps, mapDispatchToProps)(YoutubePlayer);