import {
	YOUTUBE_PLAYER_SET_YOUTUBE_ID,
} from 'redux/constants/youtubePlayer';

const initialState = {
	youtubeID: null,
};

export default (prevState = initialState, { type, youtubeID } = {}) => {
	switch(type) {
		case YOUTUBE_PLAYER_SET_YOUTUBE_ID:
			return {
				...prevState,
				youtubeID,
			};
		default:
			return prevState;
	}
};