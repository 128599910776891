import { DateTime } from 'luxon';
import FetchableData from "./FetchableData";

const convertDate = string => {
	const jsDateUtc = new Date(string);
	const localTimeString = DateTime
		.fromJSDate(jsDateUtc)
		.setZone('utc')
		.toLocal()
		.toLocaleString({
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			second: '2-digit',
		});

	return localTimeString;
};

export default new FetchableData({
	name: 'NEXT_LAUNCH',
	url: 'https://api.spacexdata.com/v3/launches/next',
	dataMiddleware: ({ mission_name, launch_date_utc, details, links }) => ({
		title: mission_name,
		date: convertDate(launch_date_utc),
		details,
		img: links?.mission_patch,
	}),
});