import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'styled/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import { setYoutubeID } from "redux/actions/youtubePlayer";

const transitionSlideUp = css`
	transition: 0.1s ease-in-out transform;
	
	${Button}:hover & {
		transform: translateY(-1.5em);
	}
`;

const Styled = {};
Styled.Icon = styled(FontAwesomeIcon)`
	font-size: 1.2em;
	position: relative;
	
	${transitionSlideUp};
`;
Styled.Text = styled.div`
	position: relative;
	
	${transitionSlideUp};
`;

export const YoutubePlayerOpenButton = (props) => {
	return (
		<Button {...props} color='red'
			onClick={() => props.setYoutubeID(props.youtubeID)}
		>
			<Styled.Icon icon={['fab', 'youtube']} />
			<Styled.Text>Youtube</Styled.Text>
		</Button>
	);
};

const mapDispatchToProps = dispatch => ({
	setYoutubeID: youtubeID => dispatch(setYoutubeID(youtubeID)),
});
export default connect(null, mapDispatchToProps)(YoutubePlayerOpenButton);