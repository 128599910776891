import React from 'react';
import styled from 'styled-components';

const TITLE_FONT_SIZE = 1.5;
const BORDER_STYLE = '2px solid #ccc';
const Styled = {
	Card: styled.div`
		border-radius: 0.3em;
		border: ${BORDER_STYLE};
		margin: 1em;
	`,
	Title: styled.header`
		font-size: ${TITLE_FONT_SIZE}em;
		font-weight: bold;
		padding: 0.5em calc(1em / ${TITLE_FONT_SIZE});
		border-bottom: ${BORDER_STYLE};
	`,
	Content: styled.div`
		padding: 1em;
	`,
};

const Card = ({ children, className }) => {
	const SubCard = ({ type, render }) => {
		const isElemOfMyType = e => e.type === type;
		const matchedElem = children.find(isElemOfMyType);

		if (matchedElem)
			return render({ children: matchedElem.props.children });
		return null;
	};

	return (
		<Styled.Card className={className}>
			<SubCard type={Card.Title}
				render={({ children }) => (
					<Styled.Title>
						{ children }
					</Styled.Title>
				)}
			/>
			<SubCard type={Card.Content}
				render={({ children }) => (
					<Styled.Content>
						{ children }
					</Styled.Content>
				)}
			/>
		</Styled.Card>
	);
};

Card.Title = function() {
	return null;
};

Card.Content = function() {
	return null;
};

export default Card;