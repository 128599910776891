import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';

import { ButtonLink } from 'styled/Button';

const Styled = {};
Styled.Text = styled.span`
	margin-left: 0.5em;
`;

export default (props) => {
	return (
		<ButtonLink {...props} primary color='#00ACEE'
			href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(props.tweet)}`}
			target='_blank' rel='noopener noreferrer'
		>
			<FontAwesomeIcon icon={['fab', 'twitter']}/>
			<Styled.Text>Tweet</Styled.Text>
		</ButtonLink>
	);
};