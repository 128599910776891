import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Styled = {};

Styled.Header = styled.header`
	font-size: 1.5em;
	padding: 2em;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

Styled.Logo = styled.div`
	border-radius: 50%;
	border: 0.15em solid lightgray;
	width: 5em;
	height: 5em;
	margin-bottom: 0.5em;
	display: flex;
	justify-content: center;
	align-items: center;
`;

Styled.LogoIcon = styled(FontAwesomeIcon)`
	font-size: 3em;
`;

Styled.Title = styled.h1`
	text-align: center;
`;

const SiteHeader = () => (
	<Styled.Header>
		<Styled.Logo>
			<Styled.LogoIcon icon={['fas', 'rocket']} />
		</Styled.Logo>
		<Styled.Title>SpaceX Launches</Styled.Title>
	</Styled.Header>
);

export default SiteHeader;