import React from 'react';
import styled from 'styled-components';

import fetchableNextLaunch from 'redux/fetchableNextLaunch';
import FetchableDataProviderFor from "./FetchableDataProviderFor";
import TweetButton from "./TweetButton";
import Card from './Card';

const FetchableNextLaunch = FetchableDataProviderFor(
	fetchableNextLaunch,
	state => ({ ...state.nextLaunch })
);

const S = {};
S.Card = styled(Card)`
	flex: 1;
`;
S.Mission = styled.article`
	width: 100%;
	display: flex;
	flex-direction: row;
`;
S.Img = styled.img`
	min-width: 8em;
	max-width: 8em;
	min-height: 8em;
	max-height: 8em;
	object-fit: contain;
		
	display: none;
	&[src*='http'] {
		display: block;
	}
`;
S.Content = styled.div`
	line-height: 1.3em;
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
`;
S.Date = styled.p`
	color: gray;
`;

const NextLaunch = ({ className }) => {
	return (
		<S.Card className={className}>
			<Card.Title>Next Launch</Card.Title>
			<Card.Content>
				<S.Mission>
					<FetchableNextLaunch>
						{(mission) => (
							<React.Fragment>
								<S.Img src={mission.img} alt='mission patch' />
								<S.Content>
									<h1>{mission.title}</h1>
									<S.Date>{mission.date}</S.Date>
									<p>{mission.details}</p>
									<TweetButton data-testid='NextLaunchTweetButton'
										tweet={`So excited for ${mission.title} ! @SpaceX @elonmusk ${mission.img || ''}`}
									/>
								</S.Content>
							</React.Fragment>
						)}
					</FetchableNextLaunch>
				</S.Mission>
			</Card.Content>
		</S.Card>
	);
};

export default NextLaunch;