import axios from "axios";

const CONSTANTS_PREFIX = 'FETCH';
const REQUEST = 'REQUEST';
const FAILURE = 'FAILURE';
const SUCCESS = 'SUCCESS';

const cachedProperty = (that, name, create) => {
	const cached = that[name];
	if (cached)
		return cached;
	return that[name] = create.bind(that)();
};

export default class FetchableData {
	constructor({ name, url, dataMiddleware = d => d }) {
		this._name = name;
		this._url = url;
		this._dataMiddleware = dataMiddleware;
		this._reducer = null;
	}

	get action() {
		return cachedProperty(this, '_action', this._createAction);
	}

	get reducer() {
		return cachedProperty(this, '_reducer', this._createReducer);
	}

	_createAction() {
		const fetchRequest = () => ({
			type: this._constantName(REQUEST),
		});

		const fetchFailure = (error) => ({
			type: this._constantName(FAILURE),
			error,
		});

		const fetchSuccess = (data) => ({
			type: this._constantName(SUCCESS),
			data,
		});

		return () => (dispatch) => {
			dispatch(fetchRequest());

			return axios.get(this._url)
					.then(res => {
						dispatch(fetchSuccess(this._dataMiddleware(res.data)));
					})
					.catch(error => {
						dispatch(fetchFailure(error));
					});
		};
	}


	_createReducer() {
		const initialState = {
			pending: true,
			error: null,
			data: null,
		};

		return (prevState = initialState, {type, data, error} = {}) => {
			switch (type) {
				case this._constantName(REQUEST):
					return {
						...prevState,
						pending: true,
						error: null,
						data: null,
					};
				case this._constantName(FAILURE):
					return {
						...prevState,
						pending: false,
						error,
						data: null,
					};
				case this._constantName(SUCCESS):
					return {
						...prevState,
						pending: false,
						error: null,
						data,
					};
				default:
					return prevState;
			}
		};
	}

	_constantName(suffix) {
		return [CONSTANTS_PREFIX, this._name, suffix].join('_');
	}
}
