import { library } from '@fortawesome/fontawesome-svg-core';

import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(
	faRocket,
	faYoutube,
	faTwitter,
);
