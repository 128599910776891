import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';
import { connect } from "react-redux";

export const FetchableDataProvider = ({ fetchData, pending, error, data, children }) => {
	React.useEffect(() => {
		fetchData();
	}, [fetchData]);

	let content;

	if (pending)
		content = <Loader />;
	else if (error)
		content = <p>Error: { error }</p>;
	else
		content = children(data);

	return (
		<React.Fragment>
			{ content }
		</React.Fragment>
	);
};

FetchableDataProvider.propTypes = {
	children: PropTypes.func.isRequired,
};

const FetchableDataProviderFor = (fetchableData, mapFetchableState) => {
	const mapDispatchToProps = dispatch => ({
		fetchData: () => dispatch(fetchableData.action()),
	});
	return connect(mapFetchableState, mapDispatchToProps)(FetchableDataProvider);
};

export default FetchableDataProviderFor;