import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Styled = {};
Styled.Container = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	
	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const YoutubeVideo = ({ youtubeID }) => {
	const src = `https://www.youtube.com/embed/${youtubeID}`;

	return (
		<Styled.Container>
			<iframe
				title={youtubeID}
				width='100%' height='100%' frameBorder="0"
				src={src}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</Styled.Container>
	);
};

YoutubeVideo.propTypes = {
	youtubeID: PropTypes.string.isRequired,
};

export default YoutubeVideo;